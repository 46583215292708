const addAsync = (url) => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    addAsync("https://apis.google.com/js/platform.js")
    // addAsync("https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js")
  }
}

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-extras-js": () => import("./../../../src/pages/extras.js" /* webpackChunkName: "component---src-pages-extras-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lessons-js": () => import("./../../../src/pages/lessons.js" /* webpackChunkName: "component---src-pages-lessons-js" */),
  "component---src-pages-more-js": () => import("./../../../src/pages/more.js" /* webpackChunkName: "component---src-pages-more-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-lessonpage-js": () => import("./../../../src/templates/lessonpage.js" /* webpackChunkName: "component---src-templates-lessonpage-js" */)
}

